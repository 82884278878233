<template>
  <!--begin::Mixed Widget 14-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-row">
      <div class="flex-grow-1">
        <h3 class="card-title font-weight-bolder text-center">Inscriptions</h3>
        <p style="margin:0 auto; max-width: 350px; text-align:center">
          Joueurs ayant renseigné un numéro de téléphone et Email sans validation pour le moment
        </p>
        <apexchart
          :options="chartOptions"
          :series="getValid"
          type="radialBar"
        ></apexchart>
      </div>
      <div class="flex-grow-1">
        <h3 class="card-title font-weight-bolder text-center">
          Inscriptions validées
        </h3>
        <p style="margin:0 auto; max-width: 350px; text-align:center">
          Joueurs ayant validé leur numéro de téléphone et Email
        </p>
        <apexchart
          :options="chartOptions"
          :series="getSeries"
          type="radialBar"
          style="margin: 0 auto"
        ></apexchart>
      </div>
      <!--
      <div class="pt-5">
        <p class="text-center font-weight-normal font-size-lg pb-7">
          Notes: Current sprint requires stakeholders <br />to approve newly
          amended policies
        </p>
        <a
          href="#"
          class="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3"
          >Generate Report</a
        >
      </div>-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Mixed Widget 14-->
</template>

<script>
//import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";
import { mapGetters } from "vuex";

export default {
  name: "widget-7",
  data() {
    return {
      chartOptions: {},
      series: [0]
    };
  },
  components: {
    //Dropdown3
  },
  computed: {
    ...mapGetters(["layoutConfig", "playersInfo"]),
    getTotalUser() {
      return this.playersInfo.length;
    },
    getSeries() {
      const serie =
        parseFloat(
          parseInt(sessionStorage.getItem("subscribed")) / this.getTotalUser
        ) * 100;
      if (!isNaN(serie)) return [serie.toFixed(2)];
      else return [0];
    },
    getValid() {
      const serie =
        parseFloat(
          parseInt(sessionStorage.getItem("registered")) / this.getTotalUser
        ) * 100;
      if (!isNaN(serie)) return [serie.toFixed(2)];
      else return [0];
    }
  },
  mounted() {
    // reference; kt_stats_widget_7_chart
    console.log(this.getSeries);
    this.chartOptions = {
      chart: {
        height: 200,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: this.layoutConfig("colors.gray.gray-700"),
              fontSize: "30px",
              fontWeight: "700",
              offsetY: 12,
              show: true
            }
          },
          track: {
            background: this.layoutConfig("colors.theme.light.primary"),
            strokeWidth: "100%"
          }
        }
      },
      colors: [this.layoutConfig("colors.theme.base.primary")],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };
  }
};
</script>
<style scoped>
.apexcharts-canvas{
  margin: 0 auto !important;
}
</style>