<template>
  <div>
    <div class="d-flex justify-center align-items-center">
      <b-form-select
        class="col-3 col-xs-6 numPerPage"
        v-model="perPage"
        :options="pageOptions"
      ></b-form-select>
      <div class="description found col-6 col-xs-6">
        <strong>Total: {{ totalRows }}</strong>
      </div>
    </div>

    <b-table
      striped
      hover
      :items="getItems"
      :fields="fields"
      id="my-table"
      show-empty
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    ></b-table>
    <b-pagination
      :total-rows="totalRows"
      v-model="currentPage"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PlayersList",
  data() {
    return {
      fields: [],
      perPage: 10,
      currentPage: 1,
      sortBy: "lastname",
      sortDesc: false,
      /* Number.MAX_SAFE_INTEGER = 9007199254740991 */
      pageOptions: [
        5,
        10,
        20,
        50,
        {
          value: Number.MAX_SAFE_INTEGER,
          text: "tout afficher"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["playersInfo"]),
    getItems() {
      const items = [];

      if (this.playersInfo.length > 0) {
        this.playersInfo.forEach(user => {
          let showUser = {};
          if (user) {
            showUser = {
              first_name: user.first_name,
              last_name: user.last_name,
              phone: user.phone,
              email: user.email,
              logged_at: user.logged_at,
              created_at: user.created_at
            };
            items.push(showUser);
          }
        });
      }
      return items.reverse();
    },
    totalRows() {
      return this.playersInfo.length;
    }
  },
  mounted() {
    this.fields = [
      {
        key: "first_name",
        sortable: true,
        label: "Prénom(s)"
      },
      {
        key: "last_name",
        sortable: true,
        label: "Nom"
      },
      {
        key: "phone",
        sortable: false,
        label: "Téléphone"
      },
      {
        key: "email",
        sortable: false,
        label: "Email"
      },
      {
        key: "logged_at",
        sortable: true,
        label: "Dernière connexion",
        formatter: "formatDateAssigned"
      },
      {
        key: "created_at",
        sortable: true,
        label: "Date d' inscription",
        formatter: "formatDateAssigned"
      }
    ];
  },
  methods: {
    formatDateAssigned(value) {
      return this.$moment(value).format("DD-MM-YY H:mm:ss");
    }
  }
};
</script>
