<template>
  <!--begin::List Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="font-weight-bolder text-dark">
          Dernières Activités
        </span>
        <span
          v-if="membersList && membersList.length"
          class="text-muted mt-3 font-weight-bold font-size-sm"
        >
          {{ getList.length }} membres
        </span>
      </h3>
      <div class="card-toolbar">
        <Dropdown2><i class="ki ki-bold-more-hor"></i></Dropdown2>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-4">
      <div
        v-if="membersList && membersList.length"
        class="timeline timeline-6 mt-3"
      >
        <template v-for="(item, i) in getList">
          <!--begin::Item-->
          <div class="timeline-item align-items-start" v-bind:key="i">
            <!--begin::Label-->
            <div
              class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
            >
              {{ item.time }}
            </div>
            <!--end::Label-->

            <!--begin::Badge-->
            <div class="timeline-badge">
              <i class="icon-xl" v-bind:class="item.badge"></i>
            </div>
            <!--end::Badge-->

            <!--begin::Text-->
            <div
              class="font-weight-mormal font-size-lg timeline-content text-muted pl-3"
            >
              <span
                v-bind:class="{
                  'font-weight-bolder text-dark-75': item.bold,
                  'mr-4': item.images
                }"
                v-html="item.desc"
              >
              </span>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
        </template>
      </div>
      <!--end: Items-->
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: Card-->
  <!--end: List Widget 9-->
</template>

<script>
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import { mapGetters } from "vuex";

export default {
  name: "widget-9",

  components: {
    Dropdown2
  },
  props: ["membersList"],
  methods: {
    lastElement(i) {
      return i !== this.list.length - 1;
    },
    createLastConnexion(members) {
      const sortedMembers = members
        .slice()
        .sort((a, b) => new Date(b.logged_at) - new Date(a.logged_at));
      const list = [];
      //console.log(sortedMembers);
      sortedMembers.forEach((sortedMember, index) => {
        if (
          this.$moment(sortedMember.logged_at).isAfter(
            this.$moment().subtract(96, "hours")
          )
        ) {
          //console.log(sortedMember.logged_at)
          let action = [
            "vient de lancer une partie",
            "vient de se connecter"
          ];
          const randomItem = action[Math.floor(Math.random() * action.length)];
          /*if(this.$moment(sortedMember.logged_at).isAfter(this.$moment(sortedMember.created_at))){
            action = 'vient de lancer une partie'
          }else{
           action = 'vient de se connecter au jeu'
          }*/
          const item = {
            time: `${this.$moment(sortedMember.logged_at).format("HH:mm")}`,
            desc:
              sortedMember.first_name +
              " " +
              sortedMember.last_name +
              " - " +
              randomItem,
            badge: "fa fa-genderless text-info"
          };
          if (index % 2 !== 0) {
            item.bold = true;
          }
          list.push(item);
        }
      });
      if (list.length > 10) list.length = 10;
      return list;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    getList() {
      return this.createLastConnexion(this.membersList);
    }
  }
};
</script>
