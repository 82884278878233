<template>
  <!--begin::Mixed Widget 1-->
  <div class="card card-custom bg-gray-100 card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 bg-primary py-5 pb-12">
      <h2 class="card-title font-weight-bolder text-white">
        {{ getPlayzoneName }}
      </h2>
      <div class="card-toolbar">
        <Dropdown1></Dropdown1>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body p-0 position-relative overflow-hidden">
      <!--begin::Chart-->
      <apexchart
        class="card-rounded-bottom bg-primary"
        :options="chartOptions"
        :series="getMemberPerWeek"
        type="area"
      ></apexchart>
      <!--end::Chart-->
      <!--begin::Stats-->
      <div class="card-rounded-bottom bg-primary"></div>
      <div class="card-spacer mt-n25">
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col bg-light-primary px-6 py-8 rounded-xl mr-7 mb-7">
            <span class="s_more" id="tooltip-members">?</span>
            <b-tooltip
              target="tooltip-members"
              triggers="hover"
              placement="bottomleft"
            >
              Nombre de participants au jeu
            </b-tooltip>
            <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
              <inline-svg src="/media/svg/icons/Communication/Group.svg" />
            </span>
            <a href="#" class="text-primary font-size-h6">
              <span class="font-weight-bold display-4">{{ getTotalUser }}</span>
              Membre<span v-if="getTotalUser > 1">s</span><br />
              <span class="font-weight-bold display-4">{{
                  getDayMember
              }}</span>
              Nouveau<span v-if="getDayMember > 1">x</span> Aujourd'hui
            </a>
          </div>
          <div class="col bg-light-warning px-6 py-8 rounded-xl mb-7">
            <span class="s_more" id="tooltip-inscriptions-1">?</span>
            <b-tooltip
              target="tooltip-inscriptions-1"
              triggers="hover"
              placement="bottomleft"
            >
              <b>Inscriptions:</b> Téléphone + Email renseignés<br />
              <b>Validées:</b> Téléphone + Email vérifiés<br />
            </b-tooltip>
            <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
              <inline-svg src="/media/svg/icons/Communication/Add-user.svg" />
            </span>
            <a href="#" class="text-warning font-size-h6 mt-2">
              <span class="font-weight-bold display-4">{{
                getRegistrations
              }}</span>
              Inscription<span v-if="getSubscribed > 1">s</span><br />
              <span class="font-weight-bold display-4">{{
                getSubscribed
              }}</span>
              validée<span v-if="getSubscribed > 1">s</span>
            </a>
          </div>
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col bg-light-danger px-6 py-8 rounded-xl mr-7">
            <span class="s_more" id="tooltip-game-time">?</span>
            <b-tooltip
              target="tooltip-game-time"
              triggers="hover"
              placement="bottomleft"
            >
              Le temps de jeu cumulé pour toutes les parties lancées
            </b-tooltip>
            <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <inline-svg src="/media/svg/icons/Home/Timer.svg" />
            </span>
            <a href="#" class="text-danger font-weight-bold font-size-h6 mt-2">
              <span class="d-block font-weight-bold display-4">{{
                gameTime
              }}</span
              ><span>temps de jeu cumulé ({{ gameTimeSeconds }} secondes)</span>
            </a>
          </div>
          <div class="col bg-light-success px-6 py-8 rounded-xl">
            <span class="s_more" id="tooltip-parties">?</span>
            <b-tooltip
              target="tooltip-parties"
              triggers="hover"
              placement="bottomleft"
            >
              <b>Nombre total de parties lancées</b> - un membre peut avoir
              plusieurs parties
            </b-tooltip>
            <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
              <inline-svg
                src="/media/svg/icons/Communication/Urgent-mail.svg"
              />
            </span>
            <a href="#" class="text-success font-weight-bold font-size-h6 mt-2">
              <span class="font-weight-bold display-4">{{ gameCount }}</span
              ><span> parties</span><br />
              <span class="font-weight-bold display-4">{{
                averageGameTime
              }}</span
              ><span> secondes/partie</span>
            </a>
          </div>
        </div>
        <!--end::Row-->
      </div>
      <!--end::Stats-->
      <!--end::Body-->
    </div>
    <!--end::Mixed Widget 1-->
  </div>
</template>

<script>
import momentDurationFormatSetup from "moment-duration-format";
import { mapGetters } from "vuex";
import Dropdown1 from "@/view/content/dropdown/Dropdown1";
import ApiService from "@/core/services/api.service";
export default {
  name: "widget-1",
  props:{
    playzoneId: Number,
  },
  components: {
    Dropdown1
  },
  data() {
    return {
      weeksNumber: 4,
      axisCategories: [],
      strokeColor: "#FFFFFF",
      chartOptions: {},
      seriesCount: [],
      subscribed: 0,
      registrations: 0,
      gameTime: 0,
      gameCount: 0,
      gameTimeSeconds: 0,
      averageGameTime: 0,
      series: [
        {
          name: "Net Profit",
          data: [0, 0, 0, 0]
        }
      ],
      games: "",
      playzone: ""
    };
  },
  created() {
    ApiService.post("analytics-details", { playzone_id: this.playzoneInfo.id })
        .then(({ data }) => {
          this.subscribed = data[1];
          this.registrations = data[0];
          sessionStorage.setItem('subscribed', data[1]);
          sessionStorage.setItem('registered', data[0]);
          momentDurationFormatSetup(this.$moment);
          this.gameTimeSeconds = data[2];
          this.gameTime = this.$moment
              .duration(data[2], "seconds")
              .format("d:HH:mm:ss");
          console.log(this.gameTime);
          const overall = this.gameTime.split(":");
          if(overall.length === 1){
            this.gameTime = `00h 00mn ${overall[1]}s`;
          }
          else if(overall.length === 2){
            this.gameTime = `00h ${overall[0]}mn ${overall[1]}s`;
          }
          else if (overall.length === 3) {
            this.gameTime = `${overall[0]}h ${overall[1]}mn ${overall[2]}s`;
          } else {
            this.gameTime = `${overall[0]}j ${overall[1]}h ${overall[2]}min ${overall[3]}s`;
          }
          this.gameCount = data[3];
          this.averageGameTime = (data[2] / data[3]).toFixed(3);
        })
        .catch(err => console.log(err));
  },
  computed: {
    ...mapGetters(["layoutConfig", "playzoneInfo", "gamesInfo", "playersInfo"]),
    getPlayzoneName() {
      return this.playzoneInfo.playzone_name;
    },
    getTotalUser() {
      return this.playersInfo.length;
    },
    getGames() {
      let gamesArray = null;
      if (this.gamesInfo != null) gamesArray = this.chunk(this.gamesInfo, 2);
      return gamesArray;
    },
    getSubscribed() {
      return this.subscribed;
    },
    getRegistrations() {
      return this.registrations;
    },
    getDayMember() {
      let count = 0;

      if (this.playersInfo.length && this.playersInfo.length > 0) {
        this.playersInfo.forEach(player => {
          if (this.$moment().isSame(player.created_at, "day")) {
            count++;
          }
        });
      }
      return count;
    },
    getNewMembers() {
      const users = this.getMemberWeekSubs(this.playersInfo);
      return users.slice(-1)[0];
    },
    getMemberPerWeek() {
      //console.log(this.playersInfo);
      const data = this.getMemberWeekSubs(this.playersInfo);
      return [
        {
          name: "Inscriptions",
          data
        }
      ];
    }
  },
  mounted() {
    // reference; kt_mixed_widget_1_chart
    this.seriesCount = this.getMemberPerWeek;
    const incrementWeek =
      parseInt(this.$moment().isoWeek()) + 1 - parseInt(this.weeksNumber);
    this.axisCategories = [...Array(this.weeksNumber).keys()].map(
      i => "Semaine " + parseInt(i + incrementWeek)
    );
    this.chartOptions = {
      chart: {
        type: "area",
        height: 200,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 3,
          color: this.strokeColor,
          opacity: 0.5
        }
      },
      plotOptions: {},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 0
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [this.strokeColor]
      },
      xaxis: {
        categories: this.axisCategories,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: false
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: this.layoutConfig("colors.gray.gray-300"),
            width: 1,
            dashArray: 3
          }
        }
      },
      yaxis: {
        show: false,
        min: -10,
        max: this.getTotalUser,
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: {
          formatter: function(val) {
            return val;
          }
        },
        marker: {
          show: false
        }
      },
      colors: ["transparent"],
      markers: {
        colors: [this.layoutConfig("colors.theme.light.danger")],
        strokeColor: [this.strokeColor],
        strokeWidth: 3
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0
        }
      }
    };
  },
  methods: {
    chunk(arr, size) {
      const res = [];
      for (let i = 0; i < arr.length; i++) {
        if (i % size === 0) {
          // Push a new array containing the current value to the res array
          res.push([arr[i]]);
        } else {
          // Push the current value to the current array
          res[res.length - 1].push(arr[i]);
        }
      }
      return res;
    },
    getMemberWeekSubs(playerInfos) {
      const maxWeek = parseInt(this.$moment().format("WW"));
      const minWeek =
        parseInt(this.$moment().format("WW")) -
        (parseInt(this.weeksNumber) - 1);

      const memberArrayCount = [...Array(this.weeksNumber).keys()].fill(0);

      if (playerInfos.length && playerInfos.length > 0) {
        playerInfos.forEach(player => {
          //console.log(this.$moment(player.created_at).format('w'));
          //console.log()
          const playerSubsWeek = parseInt(
            this.$moment(player.created_at).format("WW")
          );
          //console.log(playerSubsWeek)
          if (playerSubsWeek >= minWeek && playerSubsWeek <= maxWeek) {
            //console.log(player.created_at)
            const arrKey = parseInt(playerSubsWeek - minWeek);
            memberArrayCount[arrKey] += 1;
          }
        });
      }
      console.log(memberArrayCount);
      return memberArrayCount;
    }
  }
};
</script>
<style lang="css" scoped>
.s_more {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  color: black;
  background: white;
  border-radius: 1rem;
  cursor: pointer;
  top: 5px;
  right: 5px;
  text-align: center;
}
</style>
