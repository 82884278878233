<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-xxl-6">
        <template v-if="playzoneInfo.id">
          <MixedWidget1 :playzoneId="playzoneInfo.id"></MixedWidget1>
        </template>
      </div>
      <div class="col-xxl-6">
        <ListWidget9 :members-list="playersInfo"></ListWidget9>
      </div>
      <div class="col-xxl-12 order-1 order-xxl-1">
        <StatsWidget13></StatsWidget13>
      </div>
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <h3 class="card-title">Liste des membres de la Playzone</h3>
          </div>
          <div class="card-body">
            <PlayersList></PlayersList>
          </div>
        </div>
      </div>


      <div
        class="col-xxl-8 order-1 order-xxl-2"
        v-show="this.currentUser.role === 0"
      >
        <AdvancedTableWidget3></AdvancedTableWidget3>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import StatsWidget13 from "@/view/content/widgets/stats/Widget13.vue";
import PlayersList from "@/view/content/widgets/PlayersList";
import ListWidget9 from "@/view/content/widgets/list/Widget9";

export default {
  name: "dashboard",
  data() {
    return {
      playzone: {},
      users: {},
      usersTable: {
        fields: [],
        items: {}
      }
    };
  },
  components: {
    AdvancedTableWidget3,
    MixedWidget1,
    StatsWidget13,
    PlayersList,
    ListWidget9
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  computed: {
    ...mapGetters(["playzoneInfo", "gamesInfo", "playersInfo", "currentUser"])
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
